<template>
    <div class="section m-0 contact">
        <div class="container">
            <div class="row justify-content-between align-items-center">

                <div class="col-md-6">
                    <div class="heading-block border-bottom-0 bottommargin-sm">
                        <div class="badge badge-pill badge-default">About & Contact us</div>
                    </div>
                    <p>Crafty Tech is an IT service provider recognized globally as a technology expert with offices in Republic of Moldova and Ukraine. Spanning a tenure of 7 years, the customer retention rate stands at a whopping 99% for customers from US, UK, Europe, East Asia, South America, and the Middle-East.</p>
                    <p>  We offer services in key areas of enterprise mobile app development, enterprise content management solutions(CMS), customer relationship management (CRM), and software product engineering (SPE). Crafty Tech provides state of the art DevOps services to optimize software processes for businesses. We follow a consultative-driven approach for providing end-to-end expertise in providing mobility solutions. </p>
                    <p>  We pride ourselves on the fact that we have a wide span of technology experts under the same roof - one of the reasons why we are not just fan-favourite, but also recognized among the best technology companies!</p>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h4 class="mb-3">Contact Now</h4>
                            <div class="form-widget">
                                <div class="form-result"></div>
                                <form class="row mb-0" id="template-contactform-footer" name="template-contactform-footer" v-on:submit.prevent="send()">
                                    <div class="col-6 form-group mb-3">
                                        <label for="template-contactform-name">Name:*</label>
                                        <input type="text" id="template-contactform-name" name="template-contactform-name" class="form-control input-sm required" value="">
                                    </div>
                                    <div class="col-6 form-group mb-3">
                                        <label for="template-contactform-email">Email:*</label>
                                        <input type="email" id="template-contactform-email" name="template-contactform-email" class="form-control input-sm required" value="">
                                    </div>

                                    <div class="col-6 form-group mb-3">
                                        <label for="template-contactform-email">Phone:*</label>
                                        <input type="text" id="template-contactform-phone" name="template-contactform-phone" class="form-control input-sm required" value="">
                                    </div>

                                    <div class="col-6 form-group mb-3">
                                        <label for="template-contactform-email">Country:*</label>
                                        <input type="text" id="template-contactform-country" name="template-contactform-country" class="form-control input-sm required" value="">
                                    </div>

                                    <div class="col-12 form-group mb-4">
                                        <label for="template-contactform-website">Message:*</label>
                                        <textarea type="text" id="template-contactform-website" name="template-contactform-website" class="form-control input-sm required" value=""></textarea>
                                    </div>

                                    <div v-if="button" class="col-12 form-group">
                                        <button  class="button button-3d m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Send Message</button>
                                    </div>

                                    <div v-else class="col-12">

                                        <div v-if="loading" class="alert alert-warning instagram-widget-alert text-center">
                                            <div class="spinner-grow spinner-grow-sm mr-2" role="status">
                                                <span class="sr-only">Sending...</span></div>
                                            Sending.....</div>
                                        <div v-else-if="success" class="alert alert-success">
                                            <i class="icon-gift"></i><strong>Well done!</strong> The mail has been sent successfully
                                        </div>
                                    </div>

                                    <input type="hidden" name="prefix" value="template-contactform-">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "ContactFragment",   data() {
            return {
                loading:false,
                success:false,
                button: true,
            }
        },
        methods:{
            send(){
                if (  $('form#template-contactform-footer').valid() ) {
                    let formData=    $('form#template-contactform-footer').serializeObject();
                    this.button = false;
                    this.loading = true;
                    axios.post(`http://craftytech.develoapper.xyz/sendmail3.php`, {
                        body: formData
                    })
                        .then(response => {
                            console.log(response)
                            this.loading = false;
                            this.success = true;

                            $("form#template-contactform-footer").trigger('reset');
                        })
                        .catch(e => {
                            this.errors.push(e)
                        })
                }

            }
        },
        mounted: function () {
            window.$ = window.jQuery;
            $.fn.serializeObject = function () {
                var arrayData, objectData;
                arrayData = this.serializeArray();
                objectData = {};

                $.each(arrayData, function () {
                    var value;

                    if (this.value != null) {
                        value = this.value;
                    } else {
                        value = '';
                    }

                    if (objectData[this.name] != null) {
                        if (!objectData[this.name].push) {
                            objectData[this.name] = [objectData[this.name]];
                        }

                        objectData[this.name].push(value);
                    } else {
                        objectData[this.name] = value;
                    }
                });

                return objectData;
            };

        }
    }
</script>

<style scoped>
    .contact {
        background: white;
        background-size: cover;
        padding: 100px 0;
    }
    .contact::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        width: 80%;
        height: 451px;
        background-size: 1280px 451px;
        background-repeat: no-repeat;
        background-image: url('../assets/demos/seo/images/hero/hero-3.svg');
    }
</style>