<template>

    <section id="content">

            <div class="content-wrap py-0 overflow-visible">
                <div class="card mx-auto mw-md border-0 shadow rounded-xl mb-5 mb-lg-0" style="    transform: translateY(-100px);">
                    <div class="card-body p-5">

                       <div class="form-widget">
                           <form class="row mb-0" id="website-cost" >
                               <div class="col-md-4 form-group mb-4">
                                   <label for="website-cost-name">Name:</label>
                                   <input type="text" name="website-cost-name" id="website-cost-name" class="form-control required" value="" placeholder="Enter your Full Name">
                               </div>
                               <div class="col-md-4 form-group mb-4">
                                   <label for="website-cost-name">Phone:</label>
                                   <input type="text" name="website-cost-phone" id="website-cost-phone" class="form-control required" value="" placeholder="Enter your phone number">
                               </div>
                               <div class="col-md-4 form-group mb-4">
                                   <label for="website-cost-name">Email:</label>
                                   <input type="text" name="website-cost-email" id="website-cost-email" class="form-control required" value="" placeholder="Enter your email">
                               </div>
                               <div class="col-12 form-group mb-4">
                                   <label for="website-cost-1" class="mb-3">01. Are you a startup, a craft enterprise or an enterprise?</label><br>
                                   <div class="btn-group btn-group-toggle flex-wrap" data-toggle="buttons">
                                       <label for="website-cost-1-startup" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="radio" name="website-cost-1" class="required valid" id="website-cost-1-startup" autocomplete="off" value="Startup"> <i class="icon-rocket"></i> Startup
                                       </label>
                                       <label for="website-cost-1-ce" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="radio" name="website-cost-1" class="required valid" id="website-cost-1-ce" autocomplete="off" value="CE"> <i class="icon-home"></i> CE
                                       </label>
                                       <label for="website-cost-1-enterprise" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="radio" name="website-cost-1" class="required valid" id="website-cost-1-enterprise" autocomplete="off" value="Enterprise"> <i class="icon-building"></i> Enterprise
                                       </label>
                                   </div>
                               </div>

                               <div class="col-12 form-group mb-4">
                                   <label for="website-cost-2" class="mb-3">02. Select the Service</label><br>
                                   <div class="btn-group btn-group-toggle flex-wrap" data-toggle="buttons">
                                       <label for="website-cost-2-mobile" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                           <input type="checkbox" name="website-cost-2" class="required valid" id="website-cost-2-mobile" autocomplete="off" value="Mobile"> <i class="icon-mobile-alt"></i> Mobile
                                       </label>
                                       <label for="website-cost-2-web" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="checkbox" name="website-cost-2" class="required valid" id="website-cost-2-web" autocomplete="off"  value="Web"> <i class="icon-desktop"></i> Web
                                       </label>
                                       <label for="website-cost-2-ui" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="checkbox" name="website-cost-2" class="required valid" id="website-cost-2-ui" autocomplete="off" value="UI"> <i class="icon-uikit"></i> UI
                                       </label>
                                       <label for="website-cost-2-strategy" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="checkbox" name="website-cost-2" class="required valid" id="website-cost-2-strategy" autocomplete="off"  value="Strategy"> <i class="icon-settings"></i> Strategy
                                       </label>
                                       <label for="website-cost-2-marketing" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="checkbox" name="website-cost-2" class="required valid" id="website-cost-2-marketing" autocomplete="off"  value="Marketing"> <i class="icon-chart-bar1"></i> Marketing
                                       </label>
                                   </div>
                               </div>

                               <div class="col-12 form-group mb-4">
                                   <label for="website-cost-3" class="mb-3">03. Select a mobile platform!</label><br>
                                   <div class="btn-group btn-group-lg btn-group-toggle flex-wrap" data-toggle="buttons">
                                       <label for="website-cost-3-ios" class="btn btn-lg btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                           <input type="checkbox" name="website-cost-3" class="required valid" id="website-cost-3-ios" autocomplete="off"  value="Mobile"> <i class="icon-apple"></i>
                                       </label>
                                       <label for="website-cost-3-android" class="btn btn-lg btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                           <input type="checkbox" name="website-cost-3" class="required valid" id="website-cost-3-android" autocomplete="off"  value="Web"> <i class="icon-android"></i>
                                       </label>
                                   </div>
                               </div>


                               <div class="col-12 form-group mb-4">
                                   <label for="website-cost-4" class="mb-3">04. The direction of your application?</label><br>
                                   <div class="btn-group btn-group-toggle flex-wrap" data-toggle="buttons">
                                       <label for="website-cost-4-1" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-1" autocomplete="off" value="ERP/CRM Application"> ERP/CRM Application
                                       </label>
                                       <label for="website-cost-4-2" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-2" autocomplete="off" value="Retail"> Retail
                                       </label>
                                       <label for="website-cost-4-3" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-3" autocomplete="off" value="Education"> Education
                                       </label>

                                       <label for="website-cost-4-4" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-4" autocomplete="off" value="Healthcare"> Healthcare
                                       </label>
                                       <label for="website-cost-4-5" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-5" autocomplete="off" value="Productivity"> Productivity
                                       </label>
                                       <label for="website-cost-4-6" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott  mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-6" autocomplete="off" value="Business"> Business
                                       </label>
                                       <label for="website-cost-4-7" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-7" autocomplete="off" value="Sports & Fitness"> Sports & Fitness
                                       </label>
                                       <label for="website-cost-4-8" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-8" autocomplete="off" value="Music"> Music
                                       </label>

                                       <label for="website-cost-4-9" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-9" autocomplete="off" value="Photography"> Photography
                                       </label>
                                       <label for="website-cost-4-10" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-10" autocomplete="off" value="Utility"> Utility
                                       </label>
                                       <label for="website-cost-4-11" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott  mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-11" autocomplete="off" value="Books"> Books
                                       </label>
                                       <label for="website-cost-4-12" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-12" autocomplete="off" value="Social Media Application"> Social Media Application
                                       </label>
                                       <label for="website-cost-4-13" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-13" autocomplete="off" value="Entertainment"> Entertainment
                                       </label>

                                       <label for="website-cost-4-14" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-14" autocomplete="off" value="Game"> Game
                                       </label>
                                       <label for="website-cost-4-15" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott mb-3">
                                           <input type="radio" name="website-cost-4" class="required valid" id="website-cost-4-15" autocomplete="off" value="Other"> Other
                                       </label>
                                   </div>
                               </div>

                               <div class="col-12 form-group mb-4">
                                       <label for="website-cost-5" class="mb-3">05.  What is your main purpose of developing the application?</label><br>
                                       <div class="btn-group btn-group-toggle flex-wrap website-costs-5" data-toggle="buttons">
                                           <label for="website-cost-5-1"  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                                   <input type="checkbox" name="website-cost-5" class="required valid"  id="website-cost-5-1"   autocomplete="off"  value="Profit from app sales">Profit from app sales
                                           </label>
                                           <label  for="website-cost-5-2" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                                   <input type="checkbox" name="website-cost-5" class="required valid"  id="website-cost-5-2"  autocomplete="off"  value="Revenue from advertisement">Revenue from advertisement
                                           </label>
                                           <label for="website-cost-5-3"  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                                   <input type="checkbox" name="website-cost-5" class="required valid"  id="website-cost-5-3"  autocomplete="off"  value="Brand recognition">Brand recognition
                                           </label>
                                           <label for="website-cost-5-4"  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                                   <input type="checkbox" name="website-cost-5" class="required valid"  id="website-cost-5-4"  autocomplete="off"  value="Extension of Company portfolio to attract more external clients/requests">Extension of Company portfolio to attract more external clients/requests
                                           </label>
                                           <label for="website-cost-5-5"  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott">
                                                   <input type="checkbox" name="website-cost-5" class="required valid"  id="website-cost-5-5"  autocomplete="off"  value="Others">Others
                                           </label>
                                       </div>
                               </div>
                               <div class="col-12 form-group mb-4">
                                        <label for="website-cost-6" class="mb-3">06. Which of the following functions does your application need?</label><br>
                                       <div class="btn-group btn-group-toggle flex-wrap website-costs-6" data-toggle="buttons">
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Social Media Integration">Social Media Integration
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott "    >
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Server Integration">Server Integration
                                               </div>
                                           </label>

                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott " >
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Maps">Maps
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="GPS">GPS
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Audio / Video Playback">Audio / Video Playback
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Audio / Video Recording">Audio / Video Recording
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Push Notifications">Push Notifications
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Offline Mode Support">Offline Mode Support
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Chat / Messaging">Chat / Messaging
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Bluetooth Communication">Bluetooth Communication
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Graphs / Charts">Graphs / Charts
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="In App Purchase">In App Purchase
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Advertisement">Advertisement
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Analytics">Analytics
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Photos">Photos
                                               </div>
                                           </label>
                                           <label  class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott ">
                                               <div class="motivation-app-wrap">
                                                   <input type="checkbox" class="motivation-app-checkbox" name="website-cost-6" value="Others">Others
                                               </div>
                                           </label>
                                       </div>

                               </div>

                               <div class="col-12 form-group mb-4">
                                   <label for="website-cost-7" class="mb-3"> 07. Tell us a little about your idea or indicate the applications which are similar to your idea, if any!</label><br>
                                   <textarea name="website-cost-7" id="website-cost-7" class="form-control" cols="30" rows="5"></textarea>
                               </div>

                               <div class="col-8 form-group mb-4">
                                   <label for="website-cost-8" class="mb-3">08. Select the number of months to implement the application.</label><br>
                                   <input id="website-cost-8" name="website-cost-8" class="website-seo input-range-slider required" />
                               </div>

                               <div class="col-8 form-group mb-4">
                                   <label for="website-cost-9" class="mb-3">09. What is the allocated budget for your project?</label><br>
                                   <input id="website-cost-9" name="website-cost-9" class="website-seo input-range-slider required" />
                               </div>
                               <div class="col-12 form-group mb-4">
                                   <label for="website-cost-10" class="mb-3">10. Tell us a little about you and your organization.</label><br>

                                   <textarea name="website-cost-10" id="website-cost-10" class="form-control" cols="30" rows="5"></textarea>
                               </div>
                               <div v-if="button" class="col-12">
                                   <button @click="send()" type="button" name="website-cost-submit" class="btn btn-outline-primary btn-lg">Submit form</button>
                               </div>
                               <div v-else class="col-12">

                                   <div v-if="loading" class="alert alert-warning instagram-widget-alert text-center">
                                       <div class="spinner-grow spinner-grow-sm mr-2" role="status">
                                           <span class="sr-only">Sending...</span></div>
                                       Sending.....</div>
                                   <div v-else-if="success" class="alert alert-success">
                                       <i class="icon-gift"></i><strong>Well done!</strong> The brief has been sent successfully
                                   </div>
                               </div>
                           </form>
                       </div>
                    </div>
                </div>
            <ContactFragment />
        </div>
    </section>
</template>

<script>
    import axios from 'axios';
    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Brief",
        components: {
            ContactFragment,

        },

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Brief',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        data(){
            return {
                loading:false,
                success:false,
                button: true,

            }
        },
        methods:{
            send(){
                if (  $('form#website-cost').valid() ) {
                    this.button = false;
                    this.loading = true;

                    let formData = $('form#website-cost').serializeObject();
                    axios.post(`http://craftytech.develoapper.xyz/sendmail2.php`, {
                        body: formData
                    })
                        .then(response => {
                            this.loading = false;
                            this.success = true;
                            console.log(response)
                        })
                        .catch(e => {
                            this.errors.push(e)
                        })
                }
            }
        },
        mounted: function () {
            window.$ = window.jQuery;
            $.fn.serializeObject = function() {
                var arrayData, objectData;
                arrayData = this.serializeArray();
                objectData = {};

                $.each(arrayData, function() {
                    var value;

                    if (this.value != null) {
                        value = this.value;
                    } else {
                        value = '';
                    }

                    if (objectData[this.name] != null) {
                        if (!objectData[this.name].push) {
                            objectData[this.name] = [objectData[this.name]];
                        }

                        objectData[this.name].push(value);
                    } else {
                        objectData[this.name] = value;
                    }
                });

                return objectData;
            };

            let  element8 = $("#website-cost-8");
            let  element9 = $("#website-cost-9");

            element8.ionRangeSlider({
                min: 1,
                max: 10,
                from: 1,
                step: 1,
                grid: true,
                grid_snap: true,
                min_postfix: " month",
                max_postfix: " months"
            })

            element9.ionRangeSlider({
                min: 0,
                max: 100,
                from: 5,
                step: 1,
                grid: true,
                grid_snap: false,
                from_fixed: false,  // fix position of FROM handle
                to_fixed: false,     // fix position of TO handle
                grid_num: 5,
                prefix: '$',
                postfix:'k',
               // max_postfix: " +"
            })

        },
        destroyed: function () {
        }
    }
</script>

<style scoped>

    @import '../../assets/css/components/ion.rangeslider.css';
    .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
        color: #fff;
        background-color: var(--themecolor);
        border-color: var(--themecolor);
    }

    .website-costs-5 label {
        width: 70%;
        display: block;
        margin-top: 20px;
    }

    .website-costs-6 label {
        width: 256px;
        display: inline-block;
        margin-bottom: 15px;
        margin-right: 18px;
    }
</style>
<style>

    .irs--flat .irs-single {
        color: white;
        font-size: 15px;
        line-height: 1.333;
        text-shadow: none;
        padding: 1px 5px;
        background-color: #ed5565;
        border-radius: 4px;
    }
</style>